<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("Add New") }} Customer</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(insertdata)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="Customer Code."
            rules="required|min:3"
          >
            <b-form-group
              label="Customer Code."
              label-for="cusCode"
              description="eg. กรอกตัวอักษร 3-5 ตัวอักษร"
            >
              <b-form-input
                id="cusCode"
                v-model="formData.cusCode"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                maxlength="5"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Name (TH)"
            rules="required"
          >
            <b-form-group label="Name (TH)" label-for="full-name">
              <b-form-input
                id="full-name"
                v-model="formData.cusName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Name (EN)"
            rules="required"
          >
            <b-form-group label="Name (EN)" label-for="name-eng">
              <b-form-input
                id="full-name-eng"
                v-model="formData.cusNameEng"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Tel"
            rules="required"
          >
            <b-form-group label="Tel" label-for="tel">
              <b-form-input
                id="tel"
                v-model="formData.cusTel"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group label="Email" label-for="email">
              <b-form-input
                id="email"
                v-model="formData.cusEmail"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Address"
            rules="required"
          >
            <b-form-group label="Address" label-for="address">
              <b-form-textarea
                id="address"
                rows="3"
                v-model="formData.cusAddress"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Note">
            <b-form-group label="Note" label-for="note">
              <b-form-textarea
                id="note"
                rows="3"
                v-model="formData.cusNote"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Line Token Alert"
          >
            <b-form-group label="Line Token Alert" label-for="Line Token Alert">
              <b-form-input
                id="line-token-alert"
                v-model="formData.lineTokenAlert"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Line Token Summary"
          >
            <b-form-group label="Line Token Summary" label-for="Line Token Summary">
              <b-form-input
                id="line-token-summary"
                v-model="formData.lineTokenSummary"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("Add") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
            {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email, min, length } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import customerService from "@/services/customerService.js";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      min,
      length,
    };
  },
  setup() {
    const blankformData = {
      cusCode: "",
      cusName: "",
      cusNameEng: "",
      cusTel: "",
      cusEmail: "",
      cusAddress: "",
      cusNote: "",
      lineTokenAlert: "",
      lineTokenSummary: "",
    };

    const formData = ref(JSON.parse(JSON.stringify(blankformData)));
    const resetformData = () => {
      formData.value = JSON.parse(JSON.stringify(blankformData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetformData);

    return {
      formData,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  methods: {
    insertdata() {
      try {
        customerService
          .insertdataCustomer(this.formData)
          .then((response) => {
            if (response.data.status == "error") {
              this.$swal({
                type: "error",
                title: "บันทึกข้อมูลไม่สำเร็จ",
                text: "Error!",
                timer: 3000,
              });
            } else {
              this.$swal({
                type: "success",
                title: "บันทึกข้อมูลสำเร็จ",
                text: "Save data successfully!",
                timer: 3000,
              });
              this.$emit("update:is-add-new-user-sidebar-active", false);
              this.$emit("refetch-data");
            }
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
  },
};
</script>

<style lang="scss">
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
